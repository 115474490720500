export const cartReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TO_CART':
            return {
                ...state,
                cart: [...state.cart, { ...action.payload, qty: 1 }],
            };
        case 'REMOVE_FROM_CART':
            return {
                ...state,
                cart: state.cart.filter((c) => c.id !== action.payload.id),
            };
        case 'CHANGE_CART_QTY':
            return {
                ...state,
                cart: state.cart.filter((c) =>
                    c.id === action.payload.id
                        ? (c.qty = action.payload.qty)
                        : c.qty
                ),
            };
        case 'UPDATE':
            return {
                ...state,
                products: action.payload.products,
                services: action.payload.services,
            };
        case 'CHANGE_LOGIN':
            return {
                ...state,
                isLogin: action.payload.state,
            };
        case 'CLEAR_CART':
            return {
                ...state,
                cart: [],
            };
        case 'CHANGE_USERNAME':
            return {
                ...state,
                userName: action.payload.userName,
            };
        case 'CHANGE_MODAL':
            return {
                ...state,
                modal: action.payload.modal,
            };
        default:
            return state;
    }
};

export const productReducer = (state, action) => {
    switch (action.type) {
        case 'SORT_BY_PRICE':
            return { ...state, sort: action.payload };
        case 'FILTER_BY_STOCK':
            return { ...state, byStock: !state.byStock };
        case 'FILTER_BY_DELIVERY':
            return { ...state, byFastDelivery: !state.byFastDelivery };
        case 'FILTER_BY_RATING':
            return { ...state, byRating: action.payload };
        case 'FILTER_BY_SEARCH':
            return { ...state, searchQuery: action.payload };
        case 'CLEAR_FILTERS':
            return { byStock: false, byFastDelivery: false, byRating: 0 };
        default:
            return state;
    }
};
